<template>
  <div>
    <AddCompany />
  </div>
</template>

<script>
import AddCompany from '@/components/Dashboard/Companies/AddCompany'
export default {
  components: {
    AddCompany,
  },
}
</script>

<style></style>
